import React from "react";
import { Paragraph } from "../../Textstyles/Styles";
import { Box } from "@sqymagma/elements";

export default ({ text1, text2 }) => {
  return (
    <Box width={1} my="24px">
      <Paragraph as="p" mb="m">
        {text1}
      </Paragraph>
      <Paragraph as="p">{text2}</Paragraph>
    </Box>
  );
};
